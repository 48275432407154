<template>
  <div class="wrapper hei">
    <!-- 扫码提示语 -->
    <tips subtit="开始会员充值"></tips>
    <!-- 键盘与版本号 -->
    <div class="res pv-4x bg-gray-dark keybord flex-grow">
      <keyboard
        v-model="numbers"
        @ok="ok"
        place="请扫会员二维码或输入会员手机号"
        @clear="clear"
        type="1"
      >
        <!-- <a-input
          slot="input"
          allow-clear
          v-model="numbers"
          placeholder="请扫会员二维码或输入会员手机号"
        >
        </a-input> -->
      </keyboard>
    </div>
    <!-- 版本号 -->
    <bottom-ota></bottom-ota>
    <!-- 打印票 弹窗 -->
    <!-- <print-ticket v-model="showDialog"></print-ticket> -->
    <!-- 查询结果弹窗 -->
    <Result
      :vis.sync="resultDialog"
      :type="resultType"
      :text="resultText"
    ></Result>
  </div>
</template>
<script>
import Tips from "@/components/common/tips";
import Keyboard from "@/components/common/keyboard";
import BottomOta from "@/components/common/bottom-ota";
// import PrintTicket from "@/components/dialog/print-ticket";

import { recharge } from "../../api/cart";
import Result from "../../components/dialog/result.vue";
import { mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    Tips,
    Keyboard,
    BottomOta,
    Result
    // PrintTicket
  },
  data() {
    return {
      numbers: "",
      resultDialog: false,
      resultType: 1,
      resultText: ""
      // showDialog: false
    };
  },
  created() {
    this.$speak("请将会员二维码对准右下角扫描器");
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig", "updateMember"]),
    // 键盘确定
    ok() {
      if (!this.numbers) {
        this.$speak("请先输入号码");
        this.resultText = "请先输入号码";
        this.resultType = 1;
        this.resultDialog = true;
        return;
      }
      // 查询是否为会员(生成购物车id)
      this.isMember();
    },

    clear() {
      this.numbers = "";
    },

    isMember() {
      recharge(
        {
          memberMobile: this.numbers
        },
        {
          customResult: true // 报错时，自定义弹窗
        }
      )
        .then(res => {
          console.log(res, "2342*----*---");
          if (res && res.id) {
            // 设置支付成功后，返回会员充值页面
            this.setEndOfPayConfig({
              logoutMember: true,
              backUrl: "/member"
            });
            this.$router.push({
              path: "/package-list",
              query: { cartId: res.id, mobile: this.numbers }
            });
          }
        })
        .catch(res => {
          const msg = (res.data && res.data.message) || "查询不到信息";
          this.numbers = "";
          this.resultText = msg;
          this.resultType = 2;
          this.resultDialog = true;
        });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.keybord {
  padding-left: @1x * 10;
  padding-right: @1x * 10;
  box-sizing: border-box;
}
</style>
