<!--
  @name: 键盘组件
  @author: 冯炎龙
  @attr: {
    v-model: 最终输出键盘的值
    // 键盘按钮，box-shadow
    shadow: {
      type: String,
      default: "0 4px 0 0 rgba(0, 0, 0, 0.3)"
    },
    // 键盘类型：1是只输入整数，2是输入小数的，3是输入身份证的
    type:{
      type: String,
      default() {
        return "1";
      }
    }
  }
  @event:{
      ok:{   // 确定按钮的点击事件
          type:Function,
          default:null
      }
      change:  //input的值有变化时触发
  } 
-->

<template>
  <div class="keyboard">
    <!-- 经过格式化处理的input框 -->
    <a-input
      v-if="defaultInput"
      allow-clear
      v-model="displayToInput"
      :placeholder="place"
      @focus="inputFocus"
      @change="inputChange"
    >
    </a-input>
    <!-- 自定义input框 -->
    <slot v-else name="input" v-bind:confirm="confirm"></slot>

    <div class="keyboard-wrapper mt-2x flex-between">
      <!-- 左侧键盘 -->
      <ul class="keyboard-list flex-start flex-wrap">
        <!-- 1-9数字 -->
        <li class="item mb-2x" v-for="item in 9" :key="item">
          <a-button
            :style="{
              'box-shadow': shadow
            }"
            class="key-size"
            type="primary"
            @click="trigger(item)"
            >{{ item }}</a-button
          >
        </li>
        <!-- 纯数字模式 -->
        <template v-if="type == '1'">
          <li class="item one bottom mb-2x">
            <a-button
              class="key-size"
              :style="{
                'box-shadow': shadow
              }"
              type="primary"
              @click="trigger(0)"
              >0</a-button
            >
          </li>
        </template>
        <!-- 有小数模式 -->
        <template v-if="type == '2'">
          <li class="item two bottom mb-2x">
            <a-button
              :style="{
                'box-shadow': shadow
              }"
              class="key-size"
              type="primary"
              @click="trigger(0)"
              >0</a-button
            >
          </li>
          <li class="item bottom mb-2x">
            <a-button
              :style="{
                'box-shadow': shadow
              }"
              class="key-size"
              type="primary"
              @click="trigger('.')"
              >.</a-button
            >
          </li>
        </template>
        <!-- 身份证模式 -->
        <template v-if="type == '3'">
          <li class="item two bottom mb-2x">
            <a-button
              :style="{
                'box-shadow': shadow
              }"
              class="key-size"
              type="primary"
              @click="trigger(0)"
              >0</a-button
            >
          </li>
          <li class="item bottom mb-2x">
            <a-button
              :style="{
                'box-shadow': shadow
              }"
              class="key-size"
              type="primary"
              @click="trigger('X')"
              >X</a-button
            >
          </li>
        </template>
      </ul>
      <!-- 右侧：退格、确定按钮 -->
      <ul class="operate-list flex-col flex-between">
        <li class="item">
          <a-button class="btn-delete" type="primary" @click="back"
            ><span class="iconfont icon-24gf-delete"></span
          ></a-button>
        </li>
        <li class="item mv-2x">
          <a-button class="btn-ok font-xxxl" type="primary" @click="confirm"
            >确定</a-button
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "KeyboardPro",
  props: {
    value: {
      type: String,
      default: ""
    },
    // 键盘按钮，box-shadow
    shadow: {
      type: String,
      default: "0 4px 2px 0 rgba(0, 0, 0, 0.3)"
    },
    type: {
      type: String,
      default() {
        return "3";
      }
    },
    // 是否需要读语音：（密码键盘，不需要读音）
    needVoice: {
      type: Boolean,
      default: true
    },
    // 是否使用键盘自带input，还是自定义slot的input
    defaultInput: {
      type: Boolean,
      default: true
    },
    place: {
      type: String,
      default: "请扫码或输入票号/取票号/订单号/手机号/身份证编号"
    },
    // 是否开启身份证读卡功能
    openIdcard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayToInput: "", // 通过格式化后，显示在input框的内容
      ready: false,
      cardInfo: ""
    };
  },
  watch: {
    value: {
      handler: function(newVal) {
        if (!newVal) {
          this.displayToInput = "";
        }
      }
    },
    deep: true,
    immediate: true
  },
  created() {
    // 监听扫码，自动填充扫码得到的内容到键盘输入框
    try {
      //开始监听扫描设备
      window.test.optScan(1);
      //桥接方法供Java调用
      window.payScan = this.payScan;
    } catch (error) {
      console.log(error);
    }
    // 身份证读卡器
    this.openIdcard && this.listen();
  },
  methods: {
    // 身份证读卡
    listen() {
      window.readIdCard = this.readIdCard;
      this.ready = true;
    },
    readIdCard(res) {
      this.cardInfo = JSON.parse(res);
      this.needVoice && this.$speak("读取成功");
      // this.displayToInput = this.cardInfo.id;
      this.$emit("input", this.cardInfo.id);
      this.keyInput(this.cardInfo.id);
      this.$emit("ok", this.cardInfo.id);
    },
    payScan(code) {
      this.needVoice && this.$speak("读取成功");
      this.$emit("input", code);
      this.$emit("ok", code);
      this.keyInput(code);
    },
    trigger(code) {
      this.needVoice && this.$speak(code);
      let str = this.value;
      // 只允许一个小数点
      if (code == "." && str.includes(".")) {
        return;
      }
      str += String(code);
      this.$emit("input", str);
      this.keyInput(str);
      this.$emit("keydown", str);
    },
    back() {
      this.needVoice && this.$speak("删除");
      let str = this.value;
      str = str.substr(0, str.length - 1);
      this.$emit("input", str);
      this.keyInput(str);
      this.$emit("dele");
    },
    confirm() {
      this.needVoice && this.$speak("确定");
      this.$emit("ok", this.value, false);
    },
    // 禁用系统默认键盘
    inputFocus() {
      console.log("input框聚焦，禁用键盘");
      document.activeElement.blur();
    },
    // 格式化，input框上显示的内容 每4个内容，空一格
    keyInput(val) {
      this.displayToInput = val.toString().replace(/(\w{4})(?=\w)/g, "$1 ");
    },
    // 清空input框内容
    inputChange(e) {
      if (!e.target.value) {
        this.needVoice && this.$speak("清空");
        this.$emit("clear");
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
/deep/.ant-input {
  height: 80px;
  font-size: 22px;
  border-radius: @1x;
  font-weight: 900;
}
/deep/.ant-input-clear-icon {
  font-size: 26px;
}
.keyboard-wrapper {
  .keyboard-list {
    width: 74%;
    .item {
      width: 31.33%;
      height: 90px;
      margin-right: 2%;
      box-sizing: border-box;
      border-radius: @1x;
      &.one {
        width: 100%;
      }
      &.two {
        width: 64.66%;
      }
      > button {
        width: 100%;
        height: 100%;
        color: #000;
        background-color: #fff;
        border-color: #fff;
        border-radius: @1x;
        // box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.operate-list {
  width: 24%;
  justify-content: stretch;
  .item {
    width: 100%;
    > button {
      width: 100%;
      color: #fff;
      box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.3);
      border-radius: @1x;
    }
    .btn-delete {
      background: #75c4fc;
      // background-color: @blue;
      border-color: #75c4fc;
    }
    .btn-delete,
    .btn-ok {
      height: 196px;
    }
    .icon-24gf-delete {
      font-size: 54px;
    }
  }
}

.key-size {
  font-size: 36px;
  // font-weight: bold;
}
</style>
