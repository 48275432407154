<template>
  <div class="package-list wrapper hei">
    <div class="container">
      <div class="card flex-start">
        <img
          class="member-logo"
          :src="memberInfoOne.photo"
          alt="member-logo"
          v-if="memberInfoOne.photo"
        />
        <img
          class="member-logo"
          src="../../assets/images/member-avatar.png"
          alt="member-avatar"
          v-else
        />
        <div class="right font-xl ml-2x">
          <p>
            <span class="mr-2x">
              {{ memberInfoOne.name }}/{{ memberInfoOne.mobile }}
            </span>
            <span class="yellow">
              <span class="iconfont icon-dengji font-xl"></span>
              {{ memberInfoOne.currentLevelName }}
            </span>
          </p>
          <p class="mt-2x gray">
            会员余额：{{ memberInfoOne.usableBalance | money }}
          </p>
          <p class="mt-1x gray">
            (其中购买余额：{{ memberInfoOne.buyBalance | money }}，赠送余额：{{
              memberInfoOne.giftBalance | money
            }})
          </p>
        </div>
      </div>
    </div>
    <p class="font-l text-c pb-1x">可充值套餐</p>
    <ul
      class="container slide flex-grow ul-wrap flex-between flex-wrap flex-item"
      v-if="rechargeList && rechargeList.length"
    >
      <li
        class="li-item gray relative mb-2x p-2x"
        v-for="(item, index) in rechargeList"
        :key="item.id"
      >
        <p class="font-xl text-1 text mb-2x">{{ item.name }}</p>
        <p class="mb-2x">会员充值余额：{{ item.chargeAmount | money }}</p>
        <p class="mb-2x">会员赠送余额：{{ item.giftAmount | money }}</p>
        <p class="mb-2x">
          赠送余额有效期：
          <span v-if="item.validPeriodType == 1"
            >充值后可使用{{ item.validDays }}天</span
          >
          <span v-else>长期有效</span>
        </p>
        <p class="mb-2x">赠送积分：{{ item.giftPoints }}</p>
        <p class="mb-2x">赠送成长值：{{ item.giftGrowth }}</p>
        <p class="mb-2x text-1">
          赠送优惠券：<template v-if="item.giftCoupons">{{
            item.giftCoupons.join(";")
          }}</template>
          <template v-else>-</template>
        </p>
        <p class="flex-between num-box">
          <span class="red">{{ item.sellingPrice | money }}</span>
          <NumberBox
            @plusClick="next => plusClick(index, next)"
            @minusClick="next => minusClick(index, next)"
          ></NumberBox>
        </p>
      </li>
    </ul>
    <div v-else class="container slide flex-grow ul-wrap flex-center">
      <a-empty class="font-l"></a-empty>
    </div>
    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">
        已选 {{ totalCount }} 项 合计：<span class="red">{{
          totalAmount | money
        }}</span>
      </p>
      <a-button type="primary" class="btn" @click="onOk">确定选择</a-button>
    </div>
  </div>
</template>
<script>
import NumberBox from "@/components/common/number-box";
import { memberInfoOne } from "@/api/member";
import { productSelfMemberrechargepackage } from "@/api/search";
import { recharge, manyProduct } from "@/api/cart";
import { mapActions } from "vuex";

export default {
  name: "packageList",
  components: {
    NumberBox
  },
  data() {
    return {
      memberInfoOne: {}, // 会员详情对象
      rechargeList: [], // 充值套餐列表
      cartId: "", // 购物车id
      productList: [], // 产品列表
      totalCount: 0, // 总数量
      totalAmount: 0 // 总金额
    };
  },
  created() {
    if (this.$route.query.cartId) {
      this.cartId = this.$route.query.cartId;
    }
    this.getMemberInfoOne(); // 获取会员详情
    this.getRechargeList(); // 获取充值套餐列表
    this.$speak("请选择需要充值的会员套餐");
  },
  methods: {
    ...mapActions("common", ["updateMember"]),
    // 获取会员详情
    getMemberInfoOne() {
      memberInfoOne({ mobile: this.$route.query.mobile })
        .then(res => {
          this.memberInfoOne = res;
          this.updateMember(res);
          // this.createCartId();
        })
        .catch(() => {});
    },

    // 获取充值套餐列表
    getRechargeList() {
      productSelfMemberrechargepackage()
        .then(res => {
          this.rechargeList = res || [];

          let arr = [];
          res.forEach(item => {
            arr.push({
              productId: item.productId, // 产品id
              spuId: item.spuId, // 产品spuid
              buyNum: 0, // 购买数量
              sellingPrice: item.sellingPrice // 总金额
            });
          });
          this.productList = arr;
        })
        .catch(() => {});
    },

    // 生成购物车id
    createCartId() {
      recharge({
        memberMobile: this.memberInfoOne.mobile || ""
      })
        .then(res => {
          this.cartId = res.id;
        })
        .catch(() => {});
    },

    // 点击加
    plusClick(currIndex, next) {
      this.productList[currIndex].buyNum++;
      next();
      this.calc();
    },

    // 点击减
    minusClick(currIndex, next) {
      this.productList[currIndex].buyNum--;
      next();
      this.calc();
    },

    // 合计
    calc() {
      this.totalCount = 0; // 初始化总数量
      this.totalAmount = 0; // 初始化总金额

      let arr = this.productList.filter(item => item.buyNum > 0);
      if (arr.length > 0) {
        arr.forEach(item => {
          this.totalCount += item.buyNum;
          this.totalAmount += item.sellingPrice * item.buyNum;
        });
      }
    },

    // 确定选择
    onOk() {
      let arr = this.productList.filter(item => item.buyNum > 0);
      if (arr.length === 0) {
        return this.$warning("您还未选择要充值的套餐");
      }

      let params = {
        id: this.cartId, // 购物车id
        buyChannels: 8, // 购买渠道：自助机
        businessCategory: 4, // 业务类型：充值
        productList: arr // 产品数组
      };
      manyProduct(params)
        .then(res => {
          this.$router.push({
            name: "member-order",
            params: { cartId: this.cartId, res: res }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

.member-logo {
  width: 120px;
  height: 120px;
  border-radius: @2x;
}

.ul-wrap {
  max-width: calc(1080 - @rightMenuWidth);
  .li-item {
    background-color: #fff;
    border-radius: @2x;
    box-sizing: border-box;
    width: 48%;
    height: 388px;
  }
  .num-box {
    position: absolute;
    right: 15px;
    bottom: 10px;
    left: 15px;
  }
}
</style>
