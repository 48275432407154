<template>
  <div class="recharge-order wrapper hei">
    <!-- 返回菜单 -->
    <back text="提交订单"></back>
    <div class="order-wrap bg-white shadow p-4x mv-2x mh-x flex-grow slide">
      <div
        class="bd order-box p-3x box-size mb-2x"
        v-for="(item, index) in productItemList"
        :key="item.productId"
      >
        <div class="font-xl">
          <div class="flex-between">
            <p class="title text-1">{{ item.productName }}</p>
            <span
              @click="deleteItem(index)"
              class="iconfont icon-delete font-xl gray"
            ></span>
          </div>
          <p class="gray mid-text">会员充值套餐</p>
          <p class="flex-between">
            <span class="red">{{
              (item.sellingPrice * item.buyNum) | money
            }}</span>
            <number-box
              class="num-box"
              :init="item.buyNum"
              @plusClick="next => plusClick(index, next)"
              @minusClick="next => minusClick(index, next)"
            ></number-box>
          </p>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">
        扫码支付:
        <span class="red">{{ totalAmount | money }}</span>
      </p>
      <a-button type="primary" class="btn" @click="onOk">确定支付</a-button>
    </div>
  </div>
</template>
<script>
import Back from "@/components/common/back";
import NumberBox from "@/components/common/number-box";
import { manyProduct, discountInfoCalculate } from "@/api/cart";

export default {
  name: "MemberOrder",
  components: {
    Back,
    NumberBox
  },
  data() {
    return {
      productItemList: [], // 产品列表
      memberId: "" // 会员id
    };
  },
  computed: {
    // 计算总数量
    totalCount() {
      return this.productItemList.reduce((prev, curr) => {
        return prev + curr.buyNum;
      }, 0);
    },

    // 计算总金额
    totalAmount() {
      return this.productItemList.reduce((prev, curr) => {
        return prev + curr.sellingPrice * curr.buyNum;
      }, 0);
    }
  },
  created() {
    this.productItemList = this.$route.params.res.productItemList; // 获取产品列表
  },
  methods: {
    // 点击加
    plusClick(currIndex, next) {
      next();
      this.productItemList[currIndex].buyNum++;
    },

    // 点击减
    minusClick(currIndex, next) {
      next();
      this.productItemList[currIndex].buyNum--;
    },

    // 删除产品
    deleteItem(currIndex) {
      let that = this;
      that.$confirm({
        content: "确定要删除吗？",
        onOk() {
          that.productItemList.splice(currIndex, 1); // 删除当前数据
        }
      });
    },

    // 确定支付
    onOk() {
      let arr = this.productItemList.filter(item => item.buyNum > 0);
      if (arr.length === 0) {
        return this.$warning("您还未选择要充值的套餐");
      } else {
        let productArr = [];
        arr.forEach(item => {
          productArr.push({
            productId: item.productId, // 产品id
            spuId: item.spuId, // 产品spuid
            buyNum: item.buyNum // 购买数量
          });
        });

        let params = {
          id: this.$route.params.cartId, // 购物车id
          buyChannels: 8, // 购买渠道：自助机
          businessCategory: 4, // 业务类型：充值
          productList: productArr // 产品数组
        };
        manyProduct(params)
          .then(res => {
            this.memberId = res.purchaserMemberId; // 获取会员id
            discountInfoCalculate({
              id: res.id, // 购物车id
              memberId: res.purchaserMemberId, // 会员id
              memberMobile: res.purchaserPhone // 会员手机号
            })
              .then(res => {
                this.$store.dispatch("common/setPrintingRequired", false); // 不需要显示打印组件
                this.$router.push({
                  name: "scan-pay",
                  params: { memberId: this.memberId, res: res },
                  query: { orderNumber: res.orderId }
                });
              })
              .catch(() => {});
          })
          .catch(() => {});
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.order-wrap,
.order-box {
  border-radius: @2x;
}
.order-box {
  .title {
    max-width: 550px;
  }
  .mid-text {
    line-height: 70px;
  }
}
</style>
