<template>
  <div class="tips res pv-x bg-gray-dark b-shadow">
    <div class="text-c">
      <p class="font-l mb-1x">{{ tit }}对准右下角扫描器</p>
      <h2 class="lg-size bold">{{ subtit }}</h2>
    </div>
    <div class="qr-wrap flex-center">
      <img
        src="https://obs-qmp-android.obs.cn-south-1.myhuaweicloud.com/qumaipiao_k1/image/gif/saomaqupiao.gif"
        alt="qrcode"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Tips",
  components: {},
  props: {
    tit: {
      type: String,
      default: "将门票二维码"
    },
    subtit: {
      type: String,
      default: "开始自助取票"
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.qr-wrap {
  img {
    width: 370px;
    height: 370px;
  }
}
.lg-size {
  font-size: 32px;
}
</style>
