<!-- 
  处理结果：提示弹窗
-->
<template>
  <a-modal
    :visible="vis"
    centered
    :closable="false"
    :footer="null"
    @cancel="close"
    width="460px"
    :mask-closable="false"
  >
    <div class="text-c">
      <img
        class="icon-img"
        v-if="type == 1"
        src="../../assets/images/result_tips.png"
      />
      <img
        class="icon-img"
        v-if="type == 2"
        src="../../assets/images/result_warn.png"
      />
      <p class="font-xxxl mt-2x mb-x bold">{{ text }}</p>
      <p class="gray mb-3x font-m">{{ num }}秒后自动关闭</p>
      <div class="flex-center">
        <a-button
          v-if="showLeftBtn"
          class="left-btn mr-4x"
          type="default"
          @click="remain"
        >
          关闭
        </a-button>
        <a-button
          :class="{ 'right-btn': showLeftBtn }"
          class="btn"
          type="primary"
          @click="close"
        >
          {{ btnText }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  name: "result",
  components: {},
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    // 弹窗icon: 1轻提示；2警告
    type: {
      type: [Number, String],
      default: 1
    },
    text: {
      type: String,
      default: "请先输入号码"
    },
    btnText: {
      type: String,
      default: "知道了"
    },
    // 是否显示左侧的 关闭按钮
    showLeftBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      num: 10,
      timer: null
    };
  },
  watch: {
    vis(newVal) {
      newVal && this.init();
    }
  },
  created() {
    this.timer && clearInterval(this.timer);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        if (this.num <= 0) {
          clearInterval(this.timer);
          this.$emit("update:vis", false);
          this.$emit("customAction");
          this.num = 10;
        } else {
          this.num = this.num - 1;
        }
      }, 1000);
    },
    close() {
      this.timer && clearInterval(this.timer);
      this.$emit("update:vis", false);
      this.$emit("customAction");
      this.num = 10;
    },
    remain() {
      this.timer && clearInterval(this.timer);
      this.$emit("update:vis", false);
      this.num = 10;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

.icon-img {
  width: 260px;
  height: 260px;
}
.left-btn {
  width: 140px;
  height: 60px;
  color: @gray;
  font-size: 26px;
  border-radius: @1x * 8;
  margin-bottom: 8px;
}
.btn {
  width: 220px;
  margin-bottom: 8px;
}
.right-btn {
  width: 140px;
  margin-bottom: 8px;
}
</style>
