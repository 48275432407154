var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"keyboard"},[(_vm.defaultInput)?_c('a-input',{attrs:{"allow-clear":"","placeholder":_vm.place},on:{"focus":_vm.inputFocus,"change":_vm.inputChange},model:{value:(_vm.displayToInput),callback:function ($$v) {_vm.displayToInput=$$v},expression:"displayToInput"}}):_vm._t("input",null,{"confirm":_vm.confirm}),_c('div',{staticClass:"keyboard-wrapper mt-2x flex-between"},[_c('ul',{staticClass:"keyboard-list flex-start flex-wrap"},[_vm._l((9),function(item){return _c('li',{key:item,staticClass:"item mb-2x"},[_c('a-button',{staticClass:"key-size",style:({
            'box-shadow': _vm.shadow
          }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.trigger(item)}}},[_vm._v(_vm._s(item))])],1)}),(_vm.type == '1')?[_c('li',{staticClass:"item one bottom mb-2x"},[_c('a-button',{staticClass:"key-size",style:({
              'box-shadow': _vm.shadow
            }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.trigger(0)}}},[_vm._v("0")])],1)]:_vm._e(),(_vm.type == '2')?[_c('li',{staticClass:"item two bottom mb-2x"},[_c('a-button',{staticClass:"key-size",style:({
              'box-shadow': _vm.shadow
            }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.trigger(0)}}},[_vm._v("0")])],1),_c('li',{staticClass:"item bottom mb-2x"},[_c('a-button',{staticClass:"key-size",style:({
              'box-shadow': _vm.shadow
            }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.trigger('.')}}},[_vm._v(".")])],1)]:_vm._e(),(_vm.type == '3')?[_c('li',{staticClass:"item two bottom mb-2x"},[_c('a-button',{staticClass:"key-size",style:({
              'box-shadow': _vm.shadow
            }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.trigger(0)}}},[_vm._v("0")])],1),_c('li',{staticClass:"item bottom mb-2x"},[_c('a-button',{staticClass:"key-size",style:({
              'box-shadow': _vm.shadow
            }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.trigger('X')}}},[_vm._v("X")])],1)]:_vm._e()],2),_c('ul',{staticClass:"operate-list flex-col flex-between"},[_c('li',{staticClass:"item"},[_c('a-button',{staticClass:"btn-delete",attrs:{"type":"primary"},on:{"click":_vm.back}},[_c('span',{staticClass:"iconfont icon-24gf-delete"})])],1),_c('li',{staticClass:"item mv-2x"},[_c('a-button',{staticClass:"btn-ok font-xxxl",attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }