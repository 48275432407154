<template>
  <div class="bottom-ota pb-1x">
    <div class="flex-around pv-x">
      <span>版本号：{{ version ? version : "-" }}</span>
      <span>
        门票纸数量：{{
          machineDetail.remainingVotes ? machineDetail.remainingVotes : "-"
        }}
      </span>
      <span>
        服务电话：{{
          machineDetail.serviceTelephone ? machineDetail.serviceTelephone : "-"
        }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomOta",
  components: {},
  props: {},
  data() {
    return {
      version: ""
    };
  },
  computed: {
    machineDetail() {
      return this.$store.state.setting.machineDetail;
    }
  },
  created() {
    this.getVer();
  },
  methods: {
    getVer() {
      try {
        this.version = window.test.getVersion();
      } catch (error) {
        console.log("获取软件版本失败", error);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.bottom-ota {
  background: #343a43;
  color: #fff;
}
</style>
