<!--
  计步器:
  默认，从1累加，需自定义累加值，传step
  @events:
  minusClick: 点击减,需要调用callback来执行减
  plusClick:  点击加，需要调佣callback来执行加
-->
<template>
  <div class="number-box">
    <a-icon
      :style="{ 'font-size': size }"
      class="gray"
      @click="minusClick"
      theme="filled"
      type="minus-circle"
    />
    <span class="mh-2x" :style="{ 'font-size': size }">{{ num }}</span>
    <a-icon
      :style="{ 'font-size': size }"
      class="primary"
      @click="plusClick"
      theme="filled"
      type="plus-circle"
    />
  </div>
</template>
<script>
export default {
  name: "NumberBox",
  components: {},
  props: {
    // 出始值
    init: {
      type: Number,
      default: 0
    },
    // 步进值
    step: {
      type: Number,
      default: 1
    },
    // icon及文字大小
    size: {
      type: String,
      default: "50px"
    }
  },
  data() {
    return {
      num: 1,
      isFirstPlusClick: false // 是否首次点击 加号 （为了处理语音：有些门票第一次添加是最少2张以上）
    };
  },
  created() {
    this.num = this.init;
  },
  methods: {
    minusClick() {
      if (this.num <= 0) {
        return;
      }
      let msg = "减" + this.step;
      this.$emit("minusClick", returnData => {
        // 兼容处理：购票时，有票设置了最少购买2张或以上，的场景。不适合步进器每次加1
        if (returnData) {
          this.$nextTick(() => {
            this.num = returnData.count;
            this.$speak(msg);
          });
          return;
        }
        // 正常情况，每次都是减1
        this.num -= this.step;
        this.$speak(msg);
      });
    },
    plusClick() {
      let msg = "加" + this.step;
      if (this.num == 0) {
        this.isFirstPlusClick = false; // 当数量为0时，重置
      }
      //改成需要回调才执行递增
      this.$emit("plusClick", returnData => {
        // 兼容处理：购票时，有票设置了最少购买2张或以上，的场景。不适合步进器每次加1
        if (returnData) {
          this.$nextTick(() => {
            this.num = returnData.count;
            if (!this.isFirstPlusClick) {
              const message = "加" + this.num;
              this.isFirstPlusClick = true;
              this.$speak(message);
            } else {
              this.$speak(msg);
            }
          });
          return;
        }
        // 正常情况，每次都是加1
        this.num += this.step;
        this.$speak(msg);
      });
    }
  }
};
</script>
<style lang="less" scoped></style>
